import { Box, useStyleConfig } from "@chakra-ui/react";
import React, { useContext } from "react";

import { HomePageProducts_collections } from "@app/views/Home/gqlTypes/HomePageProducts";
import { H1 } from "@components/atoms/Text/H1";
import { H2 } from "@components/atoms/Text/H2";
import { ShopContext } from "@components/legacy/ShopProvider/context";
import { Carousel } from "@components/organisms";
import CollectionItem from "@components/organisms/CollectionGrid/CollectionItem";

interface CollectionGridProps {
  collections: HomePageProducts_collections;
}

export const CollectionGrid: React.FC<CollectionGridProps> = ({
  collections,
}) => {
  const { defaultCountry } = useContext(ShopContext);

  const pStyles = useStyleConfig("p");
  const h3Styles = useStyleConfig("h3");

  return (
    <Box
      w="full"
      textAlign="left"
      padding={[
        "1.5rem 0 0.93rem 0.93rem",
        null,
        null,
        "3.75rem 0 3.75rem 3.75rem",
      ]}
    >
      <H1 __css={h3Styles} textTransform="uppercase">
        Rent the latest Dresses, Clothing and Accessories in{" "}
        {defaultCountry?.country}
      </H1>
      <H2 __css={pStyles} fontSize="17px">
        Browse our curated collections
      </H2>
      <Carousel
        spaceBetween={20}
        slides={collections?.edges.map((collection) => (
          <CollectionItem
            key={collection?.node?.slug}
            isDesktop
            name={collection?.node?.shortName}
            slug={collection?.node?.slug}
            backgroundImageUrl={
              collection?.node?.backgroundDesktopImage?.url || ""
            }
          />
        ))}
      />
    </Box>
  );
};

export default CollectionGrid;
