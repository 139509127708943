import { Box, useStyleConfig } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { H3 } from "@components/atoms/Text/H3";
import { imageLoader } from "@utils/images";

const greyDataPixel =
  "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM84gMAAdoBEhfcOz0AAAAASUVORK5CYII=";

interface CollectionItemProps {
  isDesktop?: boolean;
  slug: string;
  backgroundImageUrl: string;
  name: string;
}
const CollectionItem: React.FC<CollectionItemProps> = ({
  isDesktop,
  slug,
  backgroundImageUrl,
  name,
}) => {
  const h3Styles = useStyleConfig("h3");
  const h4Styles = useStyleConfig("h4");

  return (
    <Link href={`/collection/${slug}`} passHref>
      <Box
        as="a"
        position="relative"
        borderRadius="5px"
        border="2px dashed #4158FF"
        display="flex"
        cursor="pointer"
        flex={["0 0 160px", null, null, "0 0 309px"]}
      >
        <Image
          src={backgroundImageUrl}
          width={isDesktop ? 309 : 160}
          height={isDesktop ? 180 : 100}
          alt={name}
          loader={imageLoader}
          blurDataURL={`data:image/png;base64,${greyDataPixel}`}
          objectFit="cover"
        />
        {isDesktop ? (
          <H3
            bottom="0.5rem"
            __css={h3Styles}
            left="1rem"
            position="absolute"
            pr="4rem"
            textTransform="uppercase"
          >
            {name}
          </H3>
        ) : (
          <Box
            __css={h4Styles}
            position="absolute"
            pr="3rem"
            bottom="0.2rem"
            left="0.5rem"
            textTransform="uppercase"
          >
            {name}
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default CollectionItem;
