import { Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import React from "react";

export const H2: React.FC<BoxProps> = ({ children, ...props }) => {
  const styles = useStyleConfig("h2");

  return (
    <Box as="h2" __css={styles} {...props}>
      {children}
    </Box>
  );
};
